import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Excalidraw } from "@excalidraw/excalidraw";
import {
  ExcalidrawImperativeAPI,
} from "@excalidraw/excalidraw/types/types";
import './Schema.css';
import axios from 'axios';
import { nanoid } from 'nanoid';
import { getConfig } from './config';

function Schema() {
  const [excalidrawAPI, setExcalidrawAPI] = useState<ExcalidrawImperativeAPI | null>(null);
  const navigate = useNavigate();

  const { code } = useParams();

  useEffect(() => {
    console.log('useEffect')
    if (excalidrawAPI) {
      init();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excalidrawAPI]);

  let lastSave = '';
  
  async function init() {
    console.log('init')
    const res = await axios.post(`${getConfig().apiUrl}/scheme/getByCode`, { code });

    console.log('res', res);
    if (excalidrawAPI && res?.data) {
      const elementsJson = res.data.schema?.elements;

      if (elementsJson) {
        excalidrawAPI.updateScene({
          elements: JSON.parse(elementsJson),
          appState: {
            viewModeEnabled: res.data.schema?.read_only || false,
          }
        });
      }
    }
  }

  const onSaveEvent = async () => {
    if (excalidrawAPI) {
      const elements = excalidrawAPI.getSceneElements();
      const jsonElements = JSON.stringify(elements);
      // const appState = excalidrawAPI.getAppState();

      if (lastSave === jsonElements) {
        return;
      }
      console.log('onSave');

      lastSave = jsonElements;

      await axios.post(`${getConfig().apiUrl}/scheme/save`, {
        code,
        elements: jsonElements,
      });
    }
  }

  const newSchemaHandler = async () => {
    const newSchemeId = nanoid(40);

    navigate(`/schema/${newSchemeId}`);

    if (excalidrawAPI) {
      excalidrawAPI.updateScene({
        elements: [],
      });
    }

  }

  return (
    <div className="Schema">
      <div className='schema-container'>
        <Excalidraw
          excalidrawAPI={(api) => setExcalidrawAPI(api)}
          onPointerDown={onSaveEvent}
        />
        <button className='new-scheme' onClick={newSchemaHandler}>новая схема</button>
      </div>
    </div>
  );
}

export {
  Schema,
};
