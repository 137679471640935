import { Config } from './Types/config';

const CONFIG = process.env.REACT_APP_CONFIG ?? 'default';

const getConfig = (): Config => {
    const configData = require(`./Configs/${CONFIG}.ts`) as { config: Config };
    return configData.config;
}

export {
    getConfig
}
