import React from 'react';
import './App.css';
import { Button, Container, Row, Col, Stack } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  const cheateSchemeHandler = () => {
    const newSchemeId = nanoid(40);

    navigate(`/schema/${newSchemeId}`);
    console.log(newSchemeId);
  }

  return (
    <div className="App">
      <Container>
        <Row>
          <Col>
            <Stack direction='horizontal' className='button_container' gap={2}>
              <Button onClick={cheateSchemeHandler}>Новая схема</Button>
            </Stack>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export { App };
